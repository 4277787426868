import { FILTERS_EQUAL } from "@kraftheinz/common";
import { g as AUDIT_CONST, h as AUDIT_RECORD_STATUS_INPROGRESS, i as AUDIT_RECORD_STATUS_COMPLETED, j as AUDIT_RECORD_STATUS_APPROVED } from "./audit.status.js";
import { D as DATE_FORMAT } from "./audit.others.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "table-list-audit-history pt-3 px-5" }, [_c("a-row", { staticClass: "py-3", attrs: { "type": "flex", "justify": "space-between" } }, [_c("a-col", { attrs: { "span": 2 } }, [_c("simple-filter", { attrs: { "options": _vm.listStatus, "field": "Status" }, on: { "change": _vm.onFilterChange } })], 1), _c("a-col", [_c("keyword-filter", { attrs: { "field": "ProcessHistory", "custom-url": _vm.historyApiUrl, "placeholder": "Search by Process History" } })], 1)], 1), _c("a-table", { attrs: { "bordered": "", "columns": _vm.columns, "data-source": _vm.dataHistory, "row-key": "id" }, on: { "change": _vm.onTableChange }, scopedSlots: _vm._u([{ key: "auditDate", fn: function(auditDate) {
    return [_vm._v(_vm._s(_vm._f("moment")(auditDate, _vm.DATE_FORMAT)))];
  } }, { key: "status", fn: function(status) {
    return [status !== void 0 && status !== null ? [_c("tag-field", { attrs: { "value": _vm.AUDIT_CONST.AUDIT_RECORD_STATUS_TEXTS[status], "color": _vm.AUDIT_CONST.AUDIT_RECORD_STATUS_COLORS[status] } })] : _vm._e()];
  } }, { key: "action", fn: function(record) {
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.goToEdit(record.id);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.deleteRecord(record.id);
    } } })], 1)];
  } }]) })], 1);
};
var staticRenderFns$5 = [];
const __vue2_script$5 = {
  name: "ListAuditHistory",
  inject: ["crud"],
  data() {
    const { id, itemId } = this.$route.params;
    const historyApiUrl = `${"#{VUE_APP_API_URL}#"}/sterilization/audit-groups/${id}/items/${itemId}/histories`;
    return {
      historyApiUrl,
      groupId: id,
      itemId,
      AUDIT_CONST,
      DATE_FORMAT,
      dataHistory: [],
      listStatus: [
        {
          value: "all",
          text: "All Status",
          color: "#5C6C7F"
        },
        {
          value: AUDIT_RECORD_STATUS_INPROGRESS,
          text: "In Progress",
          color: "#FA8C16"
        },
        {
          value: AUDIT_RECORD_STATUS_COMPLETED,
          text: "Completed",
          color: "#0062FF"
        },
        {
          value: AUDIT_RECORD_STATUS_APPROVED,
          text: "Approved",
          color: "#15B982"
        }
      ],
      columns: [
        {
          title: "Audit Date",
          dataIndex: "auditDate",
          key: "AuditDate",
          scopedSlots: { customRender: "auditDate" },
          sorter: true
        },
        {
          title: "Audit Cycle",
          dataIndex: "auditCycleNo",
          key: "AuditCycleNo",
          sorter: true
        },
        {
          title: "Process History",
          dataIndex: "processHistory",
          key: "ProcessHistory",
          sorter: true
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "Status",
          scopedSlots: { customRender: "status" },
          sorter: true
        },
        {
          title: "No Of Measurement",
          dataIndex: "noOfMeasurement",
          key: "NoOfMeasurement",
          align: "right",
          sorter: true
        },
        {
          title: "TP Contact",
          dataIndex: "tpContact",
          key: "TPContact",
          sorter: true
        },
        {
          title: "PD Contact",
          dataIndex: "pdContact",
          key: "PDContact",
          sorter: true
        },
        {
          title: "Filling Line",
          dataIndex: "fillingLine",
          key: "FillingLine",
          sorter: true
        },
        {
          title: "Steriliser",
          dataIndex: "steriliser",
          key: "Steriliser",
          sorter: true
        },
        {
          title: "Action",
          key: "action",
          sorter: false,
          align: "center",
          scopedSlots: {
            customRender: "action"
          }
        }
      ]
    };
  },
  async created() {
    await this.fetchList();
  },
  methods: {
    async fetchList(customQuery = "") {
      const res = await this.crud.fetchList(this.historyApiUrl, true, true, customQuery);
      this.dataHistory = res.data;
    },
    onTableChange(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      this.crud.setPagination({
        ...this.crud.getPagination(),
        page: current,
        pageSize
      });
      const { columnKey, order } = sorter;
      this.crud.setSorter({ columnKey, order });
      this.fetchList();
    },
    onFilterChange(val) {
      if (val == "all") {
        this.fetchList();
        return;
      }
      const customQuery = `fe=((Status '${FILTERS_EQUAL}' '${val}'))`;
      this.fetchList(customQuery);
    },
    goToEdit(recordId) {
      this.$router.push({
        name: "EditAuditRecord",
        params: {
          recordId
        },
        query: {
          groupId: this.groupId,
          itemId: this.itemId
        }
      });
    },
    deleteRecord(recordId) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete?`,
        cancelText: "Cancel",
        okText: "Delete",
        okType: "danger",
        onOk: this.handleDelete(recordId)
      });
    },
    handleDelete(recordId) {
      return () => this.crud.destroyEntity(`${this.historyApiUrl}/${recordId}`, recordId).then(() => this.fetchList()).catch((err) => console.error(err));
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, null, null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var ListAuditHistory$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-audit-history" }, [_c("resource", { attrs: { "name": "sterilization.audit-groups.items.histories", "api-url": _vm.apiUrl, "list": _vm.ListAuditHistory } })], 1);
};
var staticRenderFns$4 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  name: "AuditHistory",
  data() {
    return {
      ListAuditHistory: ListAuditHistory$1,
      apiUrl: apiUrl$1
    };
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var ListAuditHistory = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "hide-pagination": true, "custom-url": _vm.listDevelopmentApiUrl, "has-active": false }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var del = ref.del;
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return del(record);
    } } })], 1)];
  } }]) }, [_c("text-field", { key: "ProjectNo", attrs: { "data-index": "projectNo", "title": "Project Number", "sorter": true, "width": 136, "align": "right" } }), _c("text-field", { key: "SbtCompleted", attrs: { "data-index": "sbtCompleted", "title": "SBT Completed", "parse": _vm.parseDate, "sorter": true, "width": 136 } }), _c("text-field", { key: "MbtCompleted", attrs: { "data-index": "mbtCompleted", "title": "MBT Completed", "parse": _vm.parseDate, "sorter": true, "width": 140 } }), _c("text-field", { key: "CanInkjetCode", attrs: { "data-index": "canInkjetCode", "title": "Can Inkjet Code", "sorter": true, "width": 138 } }), _c("icon-field", { key: "TpCompleted", attrs: { "data-index": "tpCompleted", "title": "TP Completed", "render-icon": _vm.renderIcon, "width": 110, "align": "center" } }), _c("icon-field", { key: "TpSent", attrs: { "data-index": "tpSent", "title": "TP Sent", "render-icon": _vm.renderIcon, "width": 72, "align": "center" } }), _c("text-field", { key: "InitialProduction", attrs: { "data-index": "initialProduction", "title": "Initial Production", "parse": _vm.parseDate } }), _c("icon-field", { key: "NewProcessNow", attrs: { "data-index": "newProcessNow", "title": "New Process Now In Recipe Reporting", "render-icon": _vm.renderIcon, "width": 260, "align": "center" } }), _c("icon-field", { key: "FinalRecipe", attrs: { "data-index": "finalRecipe", "title": "Final Recipe", "render-icon": _vm.renderIcon, "width": 100, "align": "center" } })], 1);
};
var staticRenderFns$3 = [];
const __vue2_script$3 = {
  name: "ListProductDevelopment",
  inject: ["crud"],
  data() {
    const { id, itemId } = this.$route.params;
    const listDevelopmentApiUrl = `${"#{VUE_APP_API_URL}#"}/sterilization/audit-groups/${id}/items/${itemId}/product-developments`;
    return {
      listDevelopmentApiUrl
    };
  },
  methods: {
    renderIcon(value) {
      if (value == 0) {
        return "";
      }
      return "check-circle";
    },
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ListProductDevelopment$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-product-development-container" }, [_c("resource", { attrs: { "name": "sterilization.audit-groups.items.product-developments", "api-url": _vm.apiUrl, "edit-route": _vm.editRoute } }, [_c("list-product-development")], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    ListProductDevelopment: ListProductDevelopment$1
  },
  data() {
    return {
      apiUrl
    };
  },
  computed: {
    editRoute() {
      return `/sterilization-audits/audit-groups/${this.$route.params.id}/item/${this.$route.params.itemId}/prod-dev/:id`;
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ListProductDevelopment = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "item-code-detail" }, [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("a-row", { staticClass: "neutral-1", attrs: { "type": "flex", "align": "middle" } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("page-header", { attrs: { "title": _vm.dataItem.itemCode && _vm.dataItem.itemCode.trim(), "sub-title": _vm.dataItem.itemDescription, "back": _vm.backTo, "has-tag": false } })], 1), _c("a-col", { staticClass: "px-5", attrs: { "span": 12 } }, [_c("a-row", { attrs: { "type": "flex", "justify": "end", "gutter": [8, 0] } }, [_c("a-col", [_c("a-button", { attrs: { "type": "primary", "ghost": "" }, on: { "click": _vm.openAddProductModal } }, [_vm._v("Add Product Development")])], 1), _c("a-col", [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.goToAddRecord } }, [_vm._v("Add Audit Record")])], 1)], 1)], 1)], 1), _c("a-tabs", { attrs: { "default-active-key": "auditHistory" } }, [_c("a-tab-pane", { key: "auditHistory", attrs: { "tab": "Audit History" } }, [_c("list-audit-history")], 1), _c("a-tab-pane", { key: "developmentStage", attrs: { "tab": "Development Stage" } }, [_c("list-product-development")], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var ItemCodeDetail_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".item-code-detail[data-v-25194911] .ant-tabs-bar{background-color:#fff}\n")();
const __vue2_script$1 = {
  name: "ItemCodeDetail",
  inject: ["resourceProps"],
  components: {
    ListAuditHistory,
    ListProductDevelopment
  },
  data() {
    const [groupProps, historyProps] = this.resourceProps;
    return {
      groupProps,
      historyProps,
      dataItem: {},
      itemId: this.$route.params.itemId,
      preItems: [
        {
          title: "Data Entry",
          path: ""
        },
        {
          title: "Audit Group",
          path: "/sterilization-audits/audit-groups"
        }
      ]
    };
  },
  computed: {
    menuItems() {
      return [
        ...this.preItems,
        {
          title: this.groupEntity.groupName,
          path: `/sterilization-audits/audit-groups/${this.groupEntity.id}`
        },
        {
          title: this.dataItem.itemCode,
          path: ""
        }
      ];
    },
    groupEntity() {
      return this.groupProps.crud.getEntity() || {};
    }
  },
  async mounted() {
    await this.groupProps.crud.fetchEntity();
    if (this.groupEntity && this.groupEntity.auditItems && this.itemId) {
      this.dataItem = this.groupEntity.auditItems.find((item) => item.id == this.itemId);
    }
  },
  methods: {
    backTo() {
      this.$router.push(`/sterilization-audits/audit-groups/${this.groupEntity.id}`);
    },
    openAddProductModal() {
      this.$router.push({
        name: "CreateProductDevelopment",
        query: {
          inkjet: this.dataItem.canInkjetCode
        }
      });
    },
    goToAddRecord() {
      this.$router.push({
        name: "CreatAuditRecord",
        query: {
          groupId: this.groupEntity.id,
          itemId: this.dataItem.id
        }
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "25194911", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ItemCodeDetail = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "item-code-detail-container" }, [_c("resource", { attrs: { "name": ["sterilization.audit-groups", "sterilization.audit-groups.items.histories"], "api-url": _vm.apiUrl } }, [_c("item-code-detail")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  name: "ItemCodeDetailIndex",
  components: {
    ItemCodeDetail
  },
  data() {
    const apiUrl2 = "#{VUE_APP_API_URL}#";
    return {
      apiUrl: apiUrl2
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
